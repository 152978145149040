import PlanDetails from "modules/modals/PlanDetails/components/PlanDetails"
import MeerkatPlanActionsContainer from "modules/modals/PlanDetails/containers/MeerkatPlanActionsContainer";
import PlanInstallActionContainer from "modules/modals/PlanDetails/containers/PlanInstallActionContainer";

const PlanDetailsContainer = ({ data, isMeerkat = false, queryFilters = {}, onComplete = () => { } }) => {

    return <PlanDetails
        item={data}
        components={{ actions: isMeerkat ? MeerkatPlanActionsContainer : PlanInstallActionContainer }}
        componentProps={{
            actions: { data, queryFilters, onComplete }
        }}
    />
}

export default PlanDetailsContainer;
