import { Autocomplete, Box, CircularProgress, InputAdornment, TextField, styled, Skeleton } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { debounce } from "@mui/material/utils";
import { useTranslation } from "react-i18next";
import { setFilters } from "store/reducers/onechips";
import { dispatch } from "store";
import { useNavigate } from "react-router";
import { ROUTES } from "routes/const";
import { trim } from "lodash";
import useFakeLoader from "hooks/useFakeLoader";


const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 16px',
    color: '#7A7B84',
    backgroundColor: '#ffffff'
}));

const GroupItems = styled('ul')({
    padding: 0
});


const OnechipsSearch = ({ data, loading, onSearch, onSelect }) => {

    const { t } = useTranslation();

    const initialLoading = useFakeLoader(1)

    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    const navigate = useNavigate();

    const newData = useMemo(() => {
        const prepareData = (data) => {
            return data.map((item) => {
                return {
                    group: 'eIDs',
                    eid: item
                };
            });
        };

        return prepareData(data ?? []);
    }, [data]);

    useEffect(() => {
        if (inputValue.length === 0) {
            setOptions([]);
            return;
        }
        onSearch(inputValue);
    }, [inputValue]);

    useEffect(() => {
        if (newData) setOptions(newData);
    }, [newData]);

    const handleonChange = (event, value, reason) => {
        onSelect(value, reason);
    };

    const handleOnInputChange = debounce((event, value, reason) => {
        if (reason !== 'input') return;
        setInputValue(value);
    }, 600);

    const onSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const search = formData.get('search');
        dispatch(setFilters({ list: { eid: trim(search) } }));
        navigate(ROUTES.ONECHIPS);
    };

    return (<>{initialLoading ? (
        <Skeleton variant="rectangular" width={'350px'}>
            <Box alignItems="left" width={'100%'} height={'40px'} direction="column">
            </Box>
        </Skeleton>
    ) : (<>
        <Box sx={{
            ml: 2,
            color: 'text.secondary'
        }}>All times in UTC</Box>
        <form onSubmit={onSubmit}>
            <Autocomplete
                sx={{
                    width: { xs: '100%', md: 350 },
                    height: '40px',
                    '& .MuiOutlinedInput-root': { paddingLeft: '10px', paddingRight: '30px !important' }
                }}
                options={options}
                size="small"
                freeSolo
                disableClearable={false}
                value={inputValue}
                autoComplete
                filterSelectedOptions
                filterOptions={(x) => x}
                groupBy={(option) => option.group}
                renderGroup={(params) => (
                    <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
                getOptionLabel={(option) => typeof option === "string" ? option : option.eid}
                onInputChange={(...args) => handleOnInputChange(...args)}
                onChange={handleonChange}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        fullWidth
                        sx={{ borderRadius: '8px', height: 'inherit' }}
                        name="search"
                        size="small"
                        {...params}
                        InputProps={{
                            ...params?.InputProps,
                            startAdornment: (
                                <InputAdornment position="start" sx={{ pl: 1 }}>
                                    <img src="/search_icon.svg" alt="" />
                                </InputAdornment>
                            ),
                            endAdornment: <>{loading ? <CircularProgress color="inherit" size={20} /> : null}</>
                        }}
                        placeholder={t('common.top_bar.search_placeholder')}
                    />
                )}
            />
        </form>
    </>
    )}
    </>
    );
};



export default OnechipsSearch;
