import AlertModal from 'components/shared/modal/Alert';
import ConfirmationModal from 'components/shared/modal/Confirmation';
import Modal from 'components/shared/modal/Modal';
import { uniqueId } from 'lodash';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

const defaultState = {};

const ModalContext = createContext(defaultState);

export const ModalProvider = ({ children }) => {

    const [configs, setConfigs] = useState({
        stacked: true
    });

    const [dialogs, setDialogs] = useState([]);
    const [registered, setRegistered] = useState({});

    const promiseRef = useRef({});

    const alert = (component = AlertModal, props = {}) => {
        createModal('alert', component, props, {});
        return new Promise((resolve, reject) => {
            promiseRef.current = { resolve, reject };
        })
    }

    const confirm = (component = ConfirmationModal, props = {}) => {
        createModal('confirm', component, { ...props, onConfirm }, {});
        return new Promise((resolve, reject) => {
            promiseRef.current = { resolve, reject };
        })
    }

    const onConfirm = () => {
        promiseRef.current.resolve();
    }

    const createModal = (type, component, props = {}, options = {}) => {
        const dialog = {
            id: uniqueId(),
            type,
            Component: component,
            props,
            ...options,
            modal: {
                closeModal,
                openModal
            }
        };
        setDialogs((dialogs) => {
            return configs.stacked ? [...dialogs, dialog] : [dialog]
        }
        );
    }

    const openModal = (component, props = {}, options = {}) => {
        createModal('modal', component, props, options);
    }

    const closeModal = () => {
        setDialogs((dialogs) => {
            const topDialog = dialogs.pop();
            if (['alert', 'confirm'].includes(topDialog.type)) {
                promiseRef.current?.resolve();
            }
            if (topDialog?.onClose) {
                topDialog.onClose();
            }
            return [...dialogs];
        });
    }

    const registerNamedModal = (name, component, props = {}, options = {}) => {
        setRegistered((registered) => {
            return {
                ...registered,
                [name]: {
                    component,
                    props,
                    options
                }
            }
        })
    }

    const openNamedModal = (name, props = {}) => {
        if (!registered[name]) {
            throw new Error(`No such modal name: ${name}`);
        }
        const { component, props: registeredProps, options } = registered[name];
        openModal(component, { ...registeredProps, ...props }, options);
    }

    const updateTopModalComponentProps = useCallback((payload = {}) => {
        setDialogs((dialogs) => {
            const dialog = dialogs.pop();
            if (!dialog) {
                return dialogs;
            }
            return [...dialogs, { ...dialog }];
        })
    }, []);

    return <ModalContext.Provider value={{ setConfigs, confirm, alert, closeModal, openModal, registerNamedModal, openNamedModal, updateTopModalComponentProps }}>
        {children}
        {dialogs.map((dialog) => {
            return <Modal key={dialog.id} {...dialog} />;
        })}
    </ModalContext.Provider>
}

export const useModal = (configs = {}) => {
    const context = useContext(ModalContext);
    if (context === undefined || context === null) {
        throw new Error(`useModal must be called within ModalProvider`)
    }

    useEffect(() => {
        context.setConfigs(prevConfig => ({ ...prevConfig, ...configs }));
    }, []);

    return context
}