import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { Typography, Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from '@mui/material';
import { ReactComponent as ErrorIcon } from 'assets/images/alert.svg';

const Error = ({ retryCallback, sx = { fontSize: '20px', lineHeight: '20px' }, ...props }) => {
    const { t } = useTranslation();
    return (
        <Stack
            direction="column"
            spacing={3}
            {...props}
            alignItems="center"
            sx={{ fontFamily: 'Inter', textAlign: 'center', width: '100%', height: '100%', color: '#D4D5D8', ...sx }}
            justifyContent="center"
            className="error-container"
        >
            <SvgIcon component={ErrorIcon} sx={{ height: '20%', width: 'auto' }} inheritViewBox />
            <Box>
                <Typography sx={sx}>{t('common.errors.network.text')}</Typography>
                <Typography component={Link} onClick={() => retryCallback()} sx={{ color: '#151F57', ...sx }}>
                    {t('common.message.retry')}
                </Typography>
            </Box>
        </Stack>
    );
};

export default Error;
