import api from '../api';

const getNotificationsInfo = async (params = {}) => {
    const { data } = await api.get(`api/notifications/info/aa`, { params });
    return data?.data?.data;
};

const getNotificationsList = async (params = {}) => {
    const { data } = await api.get(`api/notifications`, { params });
    return data;
};

const NotificationsService = {
    getNotificationsInfo,
    getNotificationsList,
};

export default NotificationsService;
