import { useAsyncNew } from './useAsyncNew';
import useJobProgress from './useJobProgress';
import useTimeout from './useTimeout';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';


const useWarningTimeout = (show = true) => {
    const seconds = 10 * 1000;
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const timeout = show ? seconds : null;
    useTimeout(() => {
        if (show)
            enqueueSnackbar(t("views.onechip_cards.actions.general_message"), { variant: "warning", persist: true });
    }, timeout);
}

const useJobProgressTracker = (service) => {
    const job = useAsyncNew(service, { immediate: false });
    const jobProgress = useJobProgress(job?.data?.requestId);

    const isError = job.isError || jobProgress.isError;
    const isSuccess = job.isSuccess && jobProgress.isSuccess;
    const isLoading = job.isLoading || jobProgress.isLoading;
    const data = jobProgress?.data;
    const error = jobProgress.error;
    const execute = job.execute;

    useWarningTimeout(isLoading);

    return { isError, isLoading, isSuccess, data, error, execute, isInitialRequest: job.isInitialRequest };
};

export default useJobProgressTracker;
