import { useEffect, useRef, useState } from 'react';
import { JobProgressStatus } from 'const/JobProgress';
import JobProgressService from 'services/job-progress/JobProgress';
import { useAsyncNew } from './useAsyncNew';
import useInterval from './useInterval';

const useJobProgress = (requestId, delayInMilliseconds = 3000) => {
    const { execute, reset, isError, isSuccess, isLoading, data, error, isInitialRequest } = useAsyncNew(JobProgressService.getJobProgress, {
        immediate: false
    });

    const loading = useRef(false);

    const value = data?.data;
    const isFailed = isError || (isSuccess && (!value || value?.status === JobProgressStatus.Failure || value?.status === JobProgressStatus.Failed));
    const isFinished = isSuccess && value && value?.status === JobProgressStatus.Finished;
    const stop = !requestId || isFailed || isFinished;

    if (isFailed || isFinished) {
        loading.current = false;
    }

    useEffect(() => {
        if (!requestId) return;
        reset({ status: 'pending' });
        loading.current = true;
    }, [requestId]);

    useInterval(
        () => {
            execute(requestId);
        },
        stop ? null : delayInMilliseconds
    );

    return { isError: isFailed, isLoading: loading.current, isSuccess: isFinished, isFinished: stop, isInitialRequest, data: value, error };
};

export default useJobProgress;
