import { Breadcrumbs, Button, Typography } from "@mui/material";
import { useModal } from 'components/shared/modal';

const ModalBreadCrumbs = ({ breadCrumbs }) => {
    const breadCrumbsList = breadCrumbs.slice(0, -1);
    const [lastBreadCrumb] = breadCrumbs.slice(-1);

    const modal = useModal({ stacked: false });

    const handleOnClick = (name) => {
        modal.openNamedModal(name);
    }

    return <Breadcrumbs aria-label="breadcrumb" sx={{
        '.MuiBreadcrumbs-separator': {
            color: '#7A7B84',
            fontWeight: 700,
            fontSize: '18px'
        }
    }}>

        {breadCrumbsList.map(({ name, title }) => (
            <Button variant="text" onClick={() => handleOnClick(name)} style={{ fontSize: '18px', color: '#7A7B84', fontFamily: 'Inter', fontWeight: 700 }}>{title}</Button>
        ))}
        <Typography style={{
            width: "62px",
            height: "22px",
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "22px",
            letterSpacing: "0px",
            textAlign: "left",
            color: "#111",
            whiteSpace: "nowrap"
        }} variant="h6">
            {lastBreadCrumb?.title}
        </Typography>
    </Breadcrumbs>
}

export default ModalBreadCrumbs;