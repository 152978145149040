import { Box, Button, Stack } from "@mui/material";
import { SvgIcon } from '@mui/material';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import CircularProgressLoader from "components/shared/loader/CircularProgressLoader";
import { useModal } from "components/shared/modal";
import useJobProgressTracker from "hooks/useJobProgressTracker";
import { AssignIp, RemoveIp, AssignPrivateIp } from "modules/onechip-details/meerkat/components/OperationModal";
import { useSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MeerkatService from "services/meerkat/Meerkat";

const withAssignIpService = (Component) => {
    return props => {
        const { filters, data, type, onComplete, ...restProps } = props;

        const { t } = useTranslation();
        const modal = useModal({ stacked: false });
        const assignIpRequest = useJobProgressTracker(MeerkatService.assignIp, { immediate: false });
        const { enqueueSnackbar } = useSnackbar();

        const handleOnComplete = () => {
            onComplete();
            modal.closeModal();
        }

        const onAssignConfirm = () => {
            executeAssignment();
        }

        const executeAssignment = () => {
            assignIpRequest.execute({
                meerkatPublicIp: type === 'public',
                meerkatPlanId: data.planId,
                eid: data.eid,
                clientId: data.clientId,
                ...filters
            });
        }

        useEffect(() => {
            if (assignIpRequest.isError) {
                enqueueSnackbar(`An error occured`, { variant: 'error' });
            }
            if (assignIpRequest.isSuccess) {
                enqueueSnackbar(t("views.onechip_cards.actions.meerkat.assign_private.assign_operational.message.success", { val: assignIpRequest?.data?.processedCount }), { variant: 'success' });
                handleOnComplete();
            }

        }, [assignIpRequest.isSuccess, assignIpRequest.isError])

        return <Component onSubmit={onAssignConfirm} loading={assignIpRequest.isLoading} {...restProps} />
    }
}

const withRemoveIpService = (Component) => {
    return props => {
        const { data, type, onComplete, ...restProps } = props;

        const { t } = useTranslation();
        const modal = useModal({ stacked: false });
        const removeIpRequest = useJobProgressTracker(MeerkatService.removeIp, { immediate: false });

        const { enqueueSnackbar } = useSnackbar();

        const handleOnComplete = () => {
            onComplete();
            modal.closeModal();
        }

        const onRemoveConfirm = () => {
            executeRemove();
        }

        const executeRemove = async () => {
            removeIpRequest.execute({
                meerkatPublicIp: type === 'public',
                meerkatPlanId: data.planId,
                eid: data.eid,
                clientId: data.clientId,
            });
        }

        useEffect(() => {
            if (removeIpRequest.isError) {
                enqueueSnackbar(`An error occured`, { variant: 'error' });
            }
            if (removeIpRequest.isSuccess) {
                enqueueSnackbar(t("views.onechip_cards.actions.meerkat.remove_ip.remove_operational.message.success", { val: removeIpRequest?.data?.processedCount }), { variant: 'success' });
                handleOnComplete();
            }
        }, [removeIpRequest.isSuccess, removeIpRequest.isError])

        return <Component onSubmit={onRemoveConfirm} loading={removeIpRequest.isLoading} {...restProps} />
    }
}

const AssignContainer = ({ data = {}, type, children, onComplete = () => { } }) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const modal = useModal({ stacked: false });

    const removeIpRequest = useJobProgressTracker(MeerkatService.removeIp, { immediate: false });

    const assignIpRequest = useJobProgressTracker(MeerkatService.assignIp, { immediate: false });

    const { meerkatPublicVpnEnabled = false, meerkatPrivateVpnEnabled = false } = data;

    const handleOnComplete = () => {
        onComplete();
        modal.closeModal();
    }

    useEffect(() => {
        if (removeIpRequest.isError) {
            enqueueSnackbar(`An error occured`, { variant: 'error' });
        }
        if (removeIpRequest.isSuccess) {
            enqueueSnackbar(t("views.onechip_cards.actions.meerkat.remove_ip.remove_operational.message.success", { val: removeIpRequest?.data?.processedCount }), { variant: 'success' });
            handleOnComplete();
        }
    }, [removeIpRequest.isSuccess, removeIpRequest.isError])

    useEffect(() => {
        if (assignIpRequest.isError) {
            enqueueSnackbar(`An error occured`, { variant: 'error' });
        }
        if (assignIpRequest.isSuccess) {
            enqueueSnackbar(t("views.onechip_cards.actions.meerkat.assign_private.assign_operational.message.success", { val: assignIpRequest?.data?.processedCount }), { variant: 'success' });
            handleOnComplete();
        }

    }, [assignIpRequest.isSuccess, assignIpRequest.isError])

    const onAssign = (event, filters = {}) => {
        event.preventDefault();
        event.stopPropagation();

        if (type === 'private' && meerkatPrivateVpnEnabled === false) {
            modal.openModal(AssignPrivateIp)
            return;
        }

        if (type === 'public') {
            modal.openModal(withAssignIpService(AssignIp),
                {
                    filters,
                    data,
                    type,
                    onComplete
                }
            )
            return;
        }
        executeAssignment(filters);
    }

    const executeAssignment = (filters = {}) => {
        assignIpRequest.execute({
            meerkatPublicIp: type === 'public',
            meerkatPlanId: data.planId,
            eid: data.eid,
            clientId: data.clientId,
            ...filters
        });
    }

    const onRemove = (event) => {
        event.preventDefault();
        event.stopPropagation();
        modal.openModal(withRemoveIpService(RemoveIp),
            {
                data,
                type,
                onComplete
            }
        )
    }
    return <>
        {children({ onAssign, onRemove, isLoadingRemove: removeIpRequest.isLoading, isLoadingAssign: assignIpRequest.isLoading })}
    </>
}

const AssignListItemContainer = ({ modal, data, type, onComplete }) => {
    const isAssigned = data.publicIp || data.privateIp;
    const { value, canRemove } = useMemo(() => {
        const defaultValue = type === 'public' ? data.publicIp : data.privateIp;
        let value = defaultValue;
        let canRemove = true;
        if (type === 'private' && data.meerkatPublic) {
            value = 'N/A';
            canRemove = false;
        }
        if (type === 'public' && !data.meerkatPublic) {
            value = 'N/A';
            canRemove = false;
        }
        return { value, canRemove };
    }, [data]);
    return <AssignContainer modal={modal} data={data} type={type} onComplete={onComplete}>
        {({ onAssign, onRemove }) =>
            <>
                {isAssigned ? <Stack direction={'row'}>
                    <Box>
                        {value}
                    </Box>
                    {canRemove &&
                        <Button onClick={onRemove} sx={{ height: '16px', minWidth: '16px' }}>
                            <SvgIcon component={TrashIcon} inheritViewBox />
                        </Button>
                    }
                </Stack>
                    : <Box>
                        N/A
                    </Box>}
            </>
        }
    </AssignContainer>
}

export const AssignButtonContainer = AssignContainer;

export default AssignListItemContainer;