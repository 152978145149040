import React, { useEffect, useMemo, useState } from "react";
import { Box, Breadcrumbs, Button, FormControlLabel, Grid, Modal, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import MainCard from "components/MainCard";
import Checkbox from "@mui/material/Checkbox";
import ImageContainer from "modules/networksapps/ImageContainer";
import KeyValueList from "components/shared/list/KeyValueList";
import CoverageMap from "modules/networksapps/CoverageMap";
import TokenService from "services/token/Token";
import { useTranslation } from "react-i18next";
import { UnitLabels } from "const/UnitLabels";
import PlanInstall from "modules/modals/PlanDetails/components/PlanInstallAction";
import ModalBreadCrumbs from "modules/modals/PlanDetails/components/ModalBreadcrumbs";

const styles = {
  modalStyles: {
    width: "100%",
    height: "auto",
    background: "#F4F5F5",
    borderRadius: 8,
    outline: "none" // Remove default focus outline
  },
  typographyStyles: {
    width: "211px",
    height: "29px",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "29px",
    letterSpacing: "0px",
    textAlign: "left"
    /* Add any additional styles here */
  },
  detailsHeaderStyles: {
    width: "62px",
    height: "22px",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#111",
    whiteSpace: "nowrap"
    /* Add any additional styles here */
  },
  detailsValueStyles: {
    width: "62px",
    top: "190px",
    left: "277.9609375px",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "Normal",
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "left"
    /* Add any additional styles here */
  },

  closeButton: {
    // ... (other button styles)
    textDecoration: "none", // Add this line to remove underline
    "&:focus": {
      outline: "none"
    },
    "&:focus-visible": {
      boxShadow: "0 0 0 2px #aaa"
    }
  },
  pricePerMbStyles: {
    width: "135px",
    height: "31px",
    fontSize: "26px",
    fontWeight: 700,
    lineHeight: "31px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#008986" // Dark TEAL

    /* Add any additional styles here */
  },
  accessPriceStyles: {
    width: "148px",
    height: "19px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "19px",
    letterSpacing: "0px",
    textAlign: "left"
    /* Add any additional styles here */
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center"
  },
  checkbox: {
    marginRight: "0", // Remove right margin
    padding: "0"     // Remove padding
  }
};


const PlanDetails = ({ components = {}, componentProps = {}, item, openInstallModal, previousPaths = [] }) => {

  const { actions: Actions = () => PlanInstall } = components;
  const { actions: actionsProps = {} } = componentProps

  const tokenService = TokenService.getData();
  const { t } = useTranslation();

  const [showCoverageMap, setShowCoverageMap] = useState(true);

  const breadCrumbs = [

  ]

  const planDescriptionInfo = useMemo(() => [
    { name: t('views.plan_details.plan_description_info.rat'), value: item?.networkTechTypes?.join("/") ?? "-" },
    { name: t('views.plan_details.plan_description_info.credential_type'), value: item?.credentialsType ?? "-" },
    { name: t('views.plan_details.plan_description_info.coverage_type'), value: item?.coverageType ?? "-" },
    { name: t('views.plan_details.plan_description_info.mcc_mnc'), value: "-" },
    { name: t('views.plan_details.plan_description_info.network_type'), value: item?.networkType ?? "-" },
    { name: t('views.plan_details.plan_description_info.smsc'), value: "-" },
    { name: t('views.plan_details.plan_description_info.network_app_uuid'), value: item?.planUuid || "-" }
  ], [item]);

  const planPriceInfo = useMemo(() => [
    { name: t('views.plan_details.plan_price_info.overage'), value: "-" },
    { name: t('views.plan_details.plan_price_info.sms_mt'), value: tokenService.data.hidePlanPrice ? ' -' : `$${item?.smsPrice ?? '-'}` },
    { name: t('views.plan_details.plan_price_info.sms_mo'), value: "-" }
  ], [item]);


  const coverageCountriesNamesList = useMemo(() => {
    const countries = item?.coverageCountries?.map((item) => {
      return item.name;
    }) || [];
    return countries.join(', ');
  }, [item])

  const toggleShowMap = () => {
    setShowCoverageMap(show => !show);
  }

  return (
    <Box position={'relative'}>
      <ModalBreadCrumbs breadCrumbs={breadCrumbs} />
      <Box style={styles.modalStyles}>
        <Box style={{ display: "flex", justifyContent: "space-between", aligndatas: "center" }}>

        </Box>
        <Grid container columnSpacing={{ xs: 1, sm: 4, md: 3 }}>
          {/*Details section*/}
          <Grid item>
            <Grid>
              <MainCard
                sx={{
                  width: "304px",
                  border: "none",
                  padding: "10px",
                  overflow: "hidden", // Apply overflow: hidden to the container
                  position: "relative" // Make sure the container has a positioning context
                }}
              >
                <Stack spacing={2}>

                  <Typography
                    variant="body1"
                    color="#111111"
                    sx={{
                      height: "15px",
                      overflow: "hidden",      // Hide overflow
                      whiteSpace: "nowrap",    // Prevent text from wrapping
                      textOverflow: "ellipsis" // Add ellipsis for overflow
                    }}
                    style={styles.typographyStyles}
                  >
                    {item?.name}
                  </Typography>

                  <Box>
                    <Typography
                      variant="p"
                      style={styles.pricePerMbStyles}
                    >
                      $  {tokenService.data.hidePlanPrice ? '-' :
                        item?.price
                      } / {item?.volumeUnit ? UnitLabels[item?.volumeUnit] : ''}
                    </Typography>
                  </Box>

                  <Stack spacing={3}>
                    <Typography
                      variant="p"
                      color="#7A7B84"
                      sx={styles.accessPriceStyles}
                    >
                      {t('views.plan_details.access')}: $ {tokenService.data.hidePlanPrice ? '-' : `${item?.networkAccessPrice ?? '0.0'}`} / mo
                    </Typography>


                    {/*images Container*/}
                    <ImageContainer images={item?.planLogoUrls} />

                    <Stack spacing={2}>
                      <KeyValueList list={planPriceInfo} />
                    </Stack>
                    <Stack spacing={3}>
                      <Actions {...actionsProps} />
                    </Stack>
                  </Stack>
                  {item?.newPlan ? <Box
                    style={{
                      position: "absolute",
                      left: "57%",
                      top: "-10px",
                      backgroundColor: "#5264C9", // Background color
                      width: "200px",
                      height: "28px",
                      transform: "rotate(35deg)", // Rotate the label 35 degrees clockwise
                      display: "flex",
                      justifyContent: "center",
                      aligndatas: "center",
                      color: "white", // Text color
                      fontFamily: "Inter",
                      weight: 600
                    }}
                  >
                    New
                  </Box> : ""}
                </Stack>

              </MainCard>
            </Grid>

            <Grid item style={{ marginTop: "25px" }}>
              <MainCard sx={{
                backgroundColor: "#fff",
                width: "100%",
                borderRadius: "8px",
                border: "none"
              }}>
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography style={styles.detailsHeaderStyles} variant="h6">{t('views.plan_details.coverage')}</Typography>
                  <Button onClick={toggleShowMap}>{t('views.plan_details.see_map')}</Button>
                </Box>
                <Box>
                  <Typography style={{ ...styles.detailsValueStyles, maxHeight: '100px', overflowY: 'auto', width: 'auto' }} variant="h6">
                    {coverageCountriesNamesList}
                  </Typography>
                </Box>

              </MainCard>
            </Grid>

          </Grid>
          <Grid item flex={1}>
            {/*Description section*/}
            <Grid item>
              <MainCard
                sx={{
                  backgroundColor: "#fff",
                  width: "100%",
                  maxHeight: "105px",
                  p: 2
                }}
              >
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography style={styles.detailsHeaderStyles} variant="h6">{t('views.plan_details.description')}</Typography>
                </Box>
                <Typography marginTop={"10px"} sx={{
                  backgroundColor: "#fff",
                  height: "75px",
                  overflowY: 'auto'
                }}>
                  {item?.description}
                </Typography>
              </MainCard>
            </Grid>
            {/*KeyValueList section*/}
            <Grid item style={{ marginTop: "25px" }}>
              <MainCard
                sx={{
                  backgroundColor: "#fff",
                  width: "100%",
                  p: 2
                }}
              >
                <Stack spacing={2}>
                  <KeyValueList list={planDescriptionInfo} />
                </Stack>
              </MainCard>
            </Grid>
            <Grid item style={{ marginTop: "25px" }}>

              <MainCard
                sx={{
                  backgroundColor: "#fff",
                  width: "100%",
                  height: "299px",
                  borderRadius: "8px"
                }}
              >
                <Typography style={styles.detailsHeaderStyles} variant="h6">{t('views.plan_details.coverage_map')}</Typography>
                <CoverageMap item={item?.coverageCountries} />
              </MainCard>
            </Grid>
            {/* Label on the right side, moved further to the right */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PlanDetails;
