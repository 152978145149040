import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import T from 'prop-types';
import Datamap from 'datamaps';
import { renderToString } from 'react-dom/server';

const CONTAINER_COMPONENT_ID = 'world-map-container';

const WorldMapChart = (props) => {
    const {
        defaultCountryColor,
        hoverBorderWidth,
        hoverColor,
        hoverBorderColor,
        popupTemplate,
        data,
        dataConfig
    } = props;

    const newData = useMemo(() => {
        const { isoCode, details } = dataConfig;
        const newData = Array.isArray(data) ? data : [];
        return newData.reduce(
            (accum, item) => {
                return ({
                    ...accum,
                    [item[isoCode]]: details(item)
                })
            }
            , {}
        );
    }, [data]);

    const [mapLink, setMapLink] = useState(null);
    const mapContainer = useRef();
    const clear = () => {
        const container = mapContainer.current;
        for (const child of Array.from(container.childNodes)) {
            container.removeChild(child);
        }
    };
    const popupTemplateCallback = useCallback(
        (...args) => renderToString(popupTemplate(...args)),
        [popupTemplate])

    const prepareMap = () => {
        clear();
        const worldMap = new Datamap({
            element: document.getElementById(CONTAINER_COMPONENT_ID),
            responsive: true,
            fills: {
                defaultFill: defaultCountryColor,
                Selected: '#D383F6',
                Active: '#807EE9',
                Overlapping: '#00B7D8',
            },
            geographyConfig: {
                highlightBorderColor: hoverBorderColor,
                highlightBorderWidth: hoverBorderWidth,
                popupTemplate: popupTemplateCallback,
                highlightFillColor: hoverColor,
            },
            data: newData,
        });



        window.addEventListener('resize', () => {
            worldMap.resize();
        });

        setMapLink(worldMap);
    };

    useEffect(() => {
        prepareMap();
    }, [data]);

    useEffect(() => {
        if (mapLink) {
            mapLink.updateChoropleth(newData);
        }
    }, [data]);

    return (
        <div
            ref={mapContainer}
            id={CONTAINER_COMPONENT_ID}
            style={{
                marginTop: '-30px',
                display: 'block',
                verticalAlign: 'middle',
                height: "inherit",
                paddingBottom: '0px !important'
            }}
        />
    );
};

WorldMapChart.defaultProps = {
    hoverBorderColor: "#FFFFFF",
    hoverBorderWidth: 2,
    popupTemplate: () => { },
    data: {},
};

WorldMapChart.propTypes = {
    defaultCountryColor: T.string.isRequired,
    hoverBorderColor: T.string,
    hoverBorderWidth: T.number,
    hoverColor: T.string.isRequired,
    popupTemplate: T.func,
    dataConfig: T.shape({
        isoCode: T.string.isRequired,
        details: T.func.isRequired
    }).isRequired,
    data: T.object,
};

export default WorldMapChart;
