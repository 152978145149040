import { memo, useCallback, useEffect, useMemo, useState } from 'react';

// material-ui
import { Grid } from "@mui/material";

import { useAsyncNew } from 'hooks/useAsyncNew';
import NetworkAppsService from 'services/network-apps/NetworkApps';
import { OneChipInfoProvider, useOneChipInfoContext } from 'contexts/OneChipInfoContext';
import PlanFilters from 'modules/networksapps/PlanFilters';
import DataComponent from 'modules/networksapps/DataComponent';

import { useModal } from 'components/shared/modal';

import PlanDetailsContainer from 'modules/modals/PlanDetails/containers/PlanDetailsContainer';
import TitleWrapper from 'modules/modals/shared/components/TitleWrapper';
import InfinityScrollWrapper from 'modules/modals/SelectPlan/components/InfinityScrollContainer';

const SelectPlan = () => {

    const modal = useModal({ stacked: false });

    const { info } = useOneChipInfoContext();
    const infoData = info.data;


    const [filters, setFilters] = useState({
        limit: 10,
        offset: 0,
    });
    const { execute, data, isSuccess, isLoading } = useAsyncNew(NetworkAppsService.getPlansList, { immediate: false });

    const [isNewData, setIsNewData] = useState(true);

    useEffect(() => {
        info.stop();
    }, []);

    useEffect(() => {
        if (info.isSuccess) {

            execute({ ...filters, sort: 'name:asc', clientId: infoData?.esim?.clientId });
        }
    }, [filters, info.isSuccess])

    const handleOpenPlanDetailsModal = (data) => {
        modal.openModal(PlanDetailsContainer, { data: { ...data, eid: infoData.esim.eid } }, {
            fullWidth: true,
            maxWidth: "md",
            close: true,
            sx: {
                '& .ModalContentWrapper-root': {
                    backgroundColor: '#F5F5F5'
                }
            }
        });
    }

    const handleOnFilter = (filters) => {
        const newFilters = { ...filters, offset: 0 };
        setFilters(prev => ({ ...prev, ...newFilters }));
        setIsNewData(true);
    }

    const handleFetchMore = useCallback(() => {
        setIsNewData(false);
        setFilters(prev => ({ ...prev, offset: prev.offset + prev.limit }));
    }, [])

    return (
        <>
            <PlanFilters
                onFilter={handleOnFilter}
                isModal={true}
            />
            <InfinityScrollWrapper
                data={data}
                isLoading={isLoading}
                onFetchMore={handleFetchMore}
                isNew={isNewData}
                isSuccess={isSuccess}
            >
                {(state) =>
                    <Grid container
                        columnSpacing={2}
                        alignItems={'right'}
                        rowSpacing={{ xs: 2 }}
                        sx={{ pt: 0, mt: 0 }}
                    >
                        {state.items.map((plan, index) => (
                            <Grid item xs={6} key={`grid-${plan._id}`} >
                                <DataComponent
                                    key={`tile-${plan._id}`}
                                    isRecommendation={false}
                                    item={plan}
                                    onClick={() => handleOpenPlanDetailsModal(plan)}
                                >
                                </DataComponent>
                            </Grid>
                        ))}
                    </Grid>
                }
            </InfinityScrollWrapper>
        </>
    )
}

const withOnechipInfoProvider = (Component) => {
    return (props) => (
        <OneChipInfoProvider>
            <Component {...props} />
        </OneChipInfoProvider>
    )
}

export default withOnechipInfoProvider(SelectPlan);