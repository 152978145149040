import LoadingButton from "@mui/lab/LoadingButton";
import { Typography, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material'
import T from 'prop-types';

const ConfirmationModal = ({
    children,
    okText,
    cancelText,
    loading,
    onConfirm,
    modal
}) => (
    <>
        <DialogContent sx={{ pb: 3, pt: 0, overflow: 'hidden' }} align="center">
            {children}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: 3, pt: 0 }}>
            <Button
                fullWidth
                onClick={modal.closeModal}
                variant={'outlined'}
                sx={{ flex: 1 }}
            >
                {cancelText}
            </Button>
            <LoadingButton
                variant="contained"
                fullWidth
                sx={{ flex: 1 }}
                loading={loading}
                disabled={loading}
                onClick={onConfirm}
            >
                {okText}
            </LoadingButton>
        </DialogActions>

    </>
);

ConfirmationModal.propTypes = {
    okText: T.string,
    cancelText: T.string,
    isOpen: T.bool.isRequired,
    title: T.string.isRequired,
    onClose: T.func.isRequired,
    onConfirm: T.func.isRequired,
    titleAlign: T.string,
    children: T.any,
    loading: T.bool
};

ConfirmationModal.defaultProps = {
    loading: false,
    isOpen: true,
    okText: 'Ok',
    cancelText: 'Cancel',
    children: '',
    titleAlign: 'center',
    onClose: () => { }
};

export default ConfirmationModal;

