import api from '../api';

const getFullPlanData = async (planId, clientId) => {
    const { data } = await api.get(`api/plan/${planId}/client/${clientId}`);
    return data;
};

const getPlansList = async (params) => {
    const { data } = await api.get(`api/plans/client`, {
        params
    });
    return data.data;
};

const getPlansRecommendationsList = async (params) => {
    const { data } = await api.get(`api/plans/recommendations`, {
        params
    });
    return data;
};

const getClientPlanPriceList = async (params) => {
    const { data } = await api.get(`api/plans/clientprice`, {
        params
    });
    return data;
};


const getCountriesList = async (params) => {
    const { data } = await api.get(`api/plans/countries`, {
        params
    });
    return data?.data;
};

const getPlanCoverage = async (params) => {
    let id = params.id;

    const { data } = await api.get(`api/plans/coverage/${id}`);
    return data?.data;
};


const NetworkAppsService = {
    getFullPlanData,
    getPlansList,
    getCountriesList,
    // getPlanById,
    getPlansRecommendationsList,
    getClientPlanPriceList,
    getPlanCoverage
};

export default NetworkAppsService;
