import React from "react";
import { Avatar, Box, Stack } from "@mui/material";
import Image from "components/image/Image";

const PlanLogo = ({ planLogoUrls, sx = {} }) => {
  // Check the number of pictures in planLogoUrls
  if (planLogoUrls.length === 1) {
    // If there's only one picture, display it inside the Avatar
    return (
      <Box style={{
        width: '88px',
        height: '86.03px',
        borderRadius: '4px',
        border: '1px',
        display: 'flex',              // Use flexbox
        alignItems: 'center',         // Vertically center the content
        justifyContent: 'center',     // Horizontally center the content
        ...sx
      }}>
        <Image
          src={planLogoUrls[0]}
          alt="Plan Logo"
          style={{
            maxWidth: '100%',         // Ensure the image doesn't exceed the container width
            maxHeight: '100%',        // Ensure the image doesn't exceed the container height
          }}
        />
      </Box>
    );
  } else {
    // If there are multiple pictures, display them in a grid format
    return (
      <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', width: '90px', height: '90px' }}>
        {planLogoUrls.slice(0, 4).map((url, index) => (
          <Stack key={index} style={{ width: '40px', height: '40px' }} direction="row" useFlexGap flexWrap="wrap">
            <Image src={url} alt={`Plan Logo ${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </Stack>
        ))}
      </Box>

    );
  }
};

export default PlanLogo;
