import { useEffect, useState } from 'react';
import { useAsyncNew } from 'hooks/useAsyncNew';
import OnechipsService from 'services/onechips/Onechips';
import { useSnackbar } from 'notistack';
import InstallPlan from 'modules/onechip-details/general-information/InstallPlan';
import { JobProgressStatus } from 'const/JobProgress';
import PlanInstallAction from 'modules/modals/PlanDetails/components/PlanInstallAction';
import { useParams } from 'react-router';

export const PlanInstallActionContainer = ({ data }) => {

    const { eid } = useParams();

    const { enqueueSnackbar } = useSnackbar();

    const service = useAsyncNew(OnechipsService.planChange, { immediate: false });

    const [requestId, setRequestId] = useState(null);

    const handleOnInstall = (item) => {
        const { planId, fallback = false } = item;
        service.execute({ changePlanId: planId, eid, lockProfile: fallback });
    }


    useEffect(() => {
        if (service.isError) {
            enqueueSnackbar("App install could not be started", { variant: 'error' });
        }
        if (service.isSuccess) {
            setRequestId(service.data?.requestId);

        }
    }, [service.isSuccess, service.isError]);

    const handlePlanchangeFinish = (status) => {
        if (status === JobProgressStatus.Failed) {
            enqueueSnackbar("App install could not be started", { variant: 'error' });
        } else {
            enqueueSnackbar("App install is in progress", { variant: 'success' });
        }
    }

    return <><PlanInstallAction
        data={data}
        openInstallModal={handleOnInstall}
    />
        {requestId && <InstallPlan requestId={requestId} onFinish={handlePlanchangeFinish} />}
    </>
}

export default PlanInstallActionContainer;