import SelectClientConfirmationModal from "components/modals/SelectClientConfirmationModal";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const withAccountSelectionNotification = (Component, config = {}) => {
    const configDefaults = {
        keepComponentShown: false,
        onNotificationCloseProp: 'onClose',
        notificationProps: { text: '' }
    }
    config = { ...configDefaults, ...config }

    return props => {
        const { t } = useTranslation();

        const defaultNotifications = {
            text: t('common.warnings.select_account')
        }

        const { notificationProps = defaultNotifications } = props;

        const { id = null } = useSelector((state) => state.account.selected);

        const [showModal, setShowModal] = useState(false);

        useEffect(() => {
            if (!Number.isInteger(id) && config.keepComponentShown === false) {
                setShowModal(true);
            }
        }, [id]);

        const handleOnClose = () => {
            setShowModal(false);
            if (config.onNotificationCloseProp && config.onNotificationCloseProp in props) {
                props[config.onNotificationCloseProp]();
            }
        }

        const componentProps = { shouldShowModal: !Number.isInteger(id), setShowModal, showModal, ...props };

        return (
            <>
                {((showModal === false && Number.isInteger(id) === true) || config.keepComponentShown) && <Component {...componentProps} />}
                <SelectClientConfirmationModal isOpen={showModal} onClose={handleOnClose} {...notificationProps} />
            </>
        )
    }
}

export default withAccountSelectionNotification;