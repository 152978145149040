import { useState } from 'react';

import { CircularProgress, FormControl, InputAdornment, OutlinedInput, Skeleton, Typography } from "@mui/material";
import useDidMountEffect from 'hooks/useDidMountEffect';

const Search = ({ sx = {}, value = '', loading = false, onSearch = () => { } }) => {

    const [inputValue, setInputValue] = useState(value)

    useDidMountEffect(() => {
        setInputValue(value)
    }, [value])

    const handleOnSearch = (event) => {
        event.preventDefault();
        const [searchField] = event.target;
        onSearch(searchField.value);
    };
    return (
        <form onSubmit={handleOnSearch}>
            <FormControl sx={sx}>
                <OutlinedInput
                    sx={{ height: '40px', ...sx }}
                    size="small"
                    id="search"
                    fullWidth
                    value={inputValue}
                    onChange={event => setInputValue(event.target.value)}
                    startAdornment={
                        <InputAdornment position="start">
                            <img src="/search_icon.svg" alt="" />
                        </InputAdornment>
                    }
                    aria-describedby="header-search-text"
                    inputProps={{
                        'aria-label': 'weight'
                    }}
                    endAdornment={<>{loading ? <CircularProgress color="inherit" size={20} /> : null}</>}
                    placeholder="Type to search..."
                />
            </FormControl>
        </form>
    );
};

export default Search;
