import { useCallback, useMemo } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import OnechipsService from 'services/onechips/Onechips'
import usePoller from '../hooks/usePoller'

const defaultState = {
    info: {},
}
const OnechipInfoContext = createContext(defaultState)

const DELAY_IN_MILLISECONDS = 60000; // one minute
export const OneChipInfoProvider = ({ children }) => {
    const { eid } = useParams();

    const serviceRequest = useCallback(async () => {
        return OnechipsService.getOnechipInfo(eid);
    }, [eid]);
    const { data: { execute, isError, isSuccess, isLoading, data, isInitialRequest }, start, stop } = usePoller(serviceRequest, DELAY_IN_MILLISECONDS, true);


    const [cachedData, setCachedData] = useState({});
    useEffect(() => {
        if (isSuccess) {
            setCachedData(data);
        }
    }, [isSuccess]);

    const contextValues = {
        info: { execute, isError, isSuccess, isLoading, isInitialRequest, data: !isSuccess ? cachedData : data, stop }
    }

    return <OnechipInfoContext.Provider value={contextValues}>{children}</OnechipInfoContext.Provider>
}

export const useOneChipInfoContext = () => {
    const context = useContext(OnechipInfoContext)
    if (context === undefined || context === null) {
        throw new Error(`useOneChipInfoContext must be called within OneChipInfoProvider`)
    }
    return context
}