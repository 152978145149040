import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "@mui/material/utils";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

const ServerAutoComplete = ({
    data,
    loading = false,
    multiple = false,
    getOptionValue,
    getOptionLabel,
    transform,
    onOpen,
    onSearch,
    onSelect,
    value,
    label,
    ...props }) => {

    const newData = useMemo(() => {
        return data?.map(item => {
            return transform(item)
        });
    }, [data])

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);

    const delayedSearch = useCallback(
        debounce((value) => {
            onSearch(value);
        }, 600), []);

    useEffect(() => {
        if (open && options.length === 0) {
            onOpen();
        }
    }, [open]);



    useEffect(() => {
        setOptions([]);
        if (inputValue === '') {
            return;
        }
        delayedSearch(inputValue);
    }, [inputValue]);



    useEffect(() => {
        if (newData)
            setOptions(newData);
    }, [newData]);

    useEffect(() => {
        setSelectedValues(prev => prev.filter(option => value.includes(getOptionValue(option))));
    }, [value])

    const makeOption = (option) => {
        return {
            label: getOptionLabel(option),
            value: getOptionValue(option),
            raw: option
        }
    }

    const handleonChange = (event, option, reason) => {
        if (!multiple) {
            option = [option];
        }
        const distinctOptions = Object.values(
            [...selectedValues, ...option].reduce((acc, obj) => ({ ...acc, [getOptionValue(obj)]: obj }), {})
        );

        const options = multiple ? distinctOptions.map(makeOption) : makeOption(option[0]);

        // save previous selected options
        setSelectedValues(distinctOptions);
        onSelect(options, reason);
        setInputValue('');
    }

    const handleOnInputChange = (event, value, reason) => {
        if (reason !== "input") return;
        setInputValue(value);
    };

    const selected = useMemo(() => {
        return multiple
            ? options.filter(option => value.includes(getOptionValue(option)))
            : options.find((option) => getOptionValue(option) === value);

    }, [options, value]);


    return <Autocomplete
        sx={{
            width: { xs: '100%' },
            height: "40px",
            "& .MuiOutlinedInput-root": { paddingLeft: "10px", paddingRight: "30px !important" }
        }}
        options={options}
        size='small'
        autoComplete
        filterSelectedOptions
        filterOptions={(options) => options}
        multiple={multiple}
        value={selected}
        inputValue={inputValue}
        getOptionLabel={getOptionLabel}
        onInputChange={handleOnInputChange}
        onChange={handleonChange}
        open={open}
        onOpen={() => {
            setOpen(true);
        }}
        onClose={() => {
            setOpen(false);
        }}
        disableClearable={true}
        loading={loading}
        renderInput={(params) => (<TextField
            label={label}
            fullWidth
            sx={{
                borderRadius: "8px", height: "inherit", padding: 0,
                "& .MuiOutlinedInput-input": {
                    padding: '0 !important'
                }
            }}
            name='search'
            size="small"
            {...params}
            InputProps={
                {
                    ...params?.InputProps,
                    startAdornment: null,
                    endAdornment: (
                        <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : <>{params?.InputProps?.endAdornment}</>}
                        </>

                    )
                }
            }
            placeholder={t("common.top_bar.search_placeholder")}

        />)
        }
        {...props}
    />
}

ServerAutoComplete.propTypes = {
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    multiple: PropTypes.bool,
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    transform: PropTypes.func,
    onOpen: PropTypes.func,
    onSearch: PropTypes.func,
    onSelect: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.any)])
};

ServerAutoComplete.defaultProps = {
    loading: false,
    multiple: false,
    getOptionValue: (option) => option,
    getOptionLabel: (option) => option,
    transform: (option) => option,
    onOpen: () => { },
    onSearch: () => { },
    onSelect: () => { },
    label: ''
};

export default ServerAutoComplete;