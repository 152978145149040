import LoadingButton from "@mui/lab/LoadingButton";
import { Typography, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import T from 'prop-types';

const AlertModal = ({
    title,
    children,
    okText,
    modal
}) => (
    <>
        <DialogTitle sx={{ pt: 3, pb: 1 }} align={'center'}>
            <>
                {title ? <Typography variant="h4">{title}</Typography> : null}
            </>
        </DialogTitle>
        <DialogContent sx={{ pb: 3, pt: 0, overflow: 'hidden' }} align="center">
            {children}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pb: 3, pt: 0 }}>
            <LoadingButton
                variant="contained"
                fullWidth
                onClick={modal.closeModal}
            >
                {okText}
            </LoadingButton>
        </DialogActions>
    </>
);

AlertModal.propTypes = {
    okText: T.string,
    title: T.string.isRequired,
    onClose: T.func.isRequired,
    titleAlign: T.string,
    children: T.any,
    loading: T.bool
};

AlertModal.defaultProps = {
    loading: false,
    okText: 'Ok',
    children: '',
    titleAlign: 'center',
    onClose: () => { }
};

export default AlertModal;

