import { Box, Button, Link, Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import QuestionIcon from 'assets/images/popup_question.svg';
import ConfirmationModal from "components/shared/modal/ConfirmationModal";
import { Trans } from 'react-i18next';
import { useMemo } from 'react';

export const AssignPrivateIp = ({ modal, onClose = () => { } }) => {

    const handleOnClose = () => {
        onClose();
        modal.closeModal();
    }

    const renderActions = () => {
        return (
            <Stack direction={'row'} spacing={1}>
                <Button
                    variant="contained"
                    sx={{ width: "200px", margin: 'auto !important' }}
                    onClick={handleOnClose}
                >
                    Ok
                </Button>
            </Stack>
        );
    };

    return (
        <Stack spacing={3} justifyContent='center' alignItems={'center'}>
            <Box component="img" src={QuestionIcon} sx={{ width: '88px' }} />
            <Typography variant="h4">
                <Trans
                    i18nKey={'views.onechip_cards.actions.meerkat.assign_private.assign_operational.title'}
                />
            </Typography>
            <Stack spacing={1} justifyContent='center' alignItems={'center'}>
                <Typography variant='subtitle2' >
                    <Trans
                        values={{ price: 5000 }}
                        i18nKey={'views.onechip_cards.actions.meerkat.assign_private.details'}
                    />
                </Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#7A7B84' }}>

                    <Trans
                        values={{ email: CONFIG.app.links.supportEmail }}
                        i18nKey={'views.onechip_cards.actions.meerkat.assign_private.description'}
                        components={{
                            Link: <Link />
                        }}
                    ></Trans>
                </Typography>
            </Stack>

            {renderActions()}

        </Stack>

    );
};

export const AssignIp = ({ onSubmit, loading, modal }) => {

    const renderActions = () => {
        return (
            <Stack direction={'row'} spacing={1} sx={{ flex: 1, width: '100%' }}>
                <Button
                    fullWidth
                    variant={'outlined'}
                    sx={{ flex: 1 }}
                    onClick={modal.closeModal}
                >
                    Cancel
                </Button>

                <LoadingButton
                    fullWidth
                    onClick={onSubmit}
                    variant="contained"
                    sx={{ flex: 1 }}
                    loading={loading}
                    disabled={loading}
                >
                    Assign
                </LoadingButton>
            </Stack>
        );
    };

    return (
        <Stack spacing={3} justifyContent='center' alignItems={'center'}>
            <Box component="img" src={QuestionIcon} sx={{ width: '88px' }} />
            <Typography variant="h4">
                <Trans
                    i18nKey={'views.onechip_cards.actions.meerkat.assign_public.assign_operational.title'}
                />
            </Typography>
            <Stack spacing={1} justifyContent='center' alignItems={'center'}>
                <Typography variant='subtitle2' >
                    <Trans
                        values={{ price: 2 }}
                        i18nKey={'views.onechip_cards.actions.meerkat.assign_public.details'}
                    />
                </Typography>
                <Typography sx={{ fontSize: '14px', textAlign: 'center', fontWeight: '500', color: '#7A7B84' }}>

                    <Trans
                        i18nKey={'views.onechip_cards.actions.meerkat.assign_public.description'}
                    />

                </Typography>
            </Stack>
            {renderActions()}
        </Stack>
    );
};


export const RemoveIp = ({ onSubmit, loading, modal }) => {

    const renderActions = () => {
        return (
            <Stack direction={'row'} spacing={1} sx={{ flex: 1, width: '100%' }}>
                <Button
                    variant={'outlined'}
                    sx={{ flex: 1 }}
                    fullWidth
                    onClick={modal.closeModal}
                >
                    Cancel
                </Button>

                <LoadingButton
                    onClick={onSubmit}
                    variant="contained"
                    color="error"
                    fullWidth
                    sx={{ flex: 1 }}
                    loading={loading}
                    disabled={loading}
                >
                    <span>Remove</span>
                </LoadingButton>
            </Stack>
        );
    };

    return (
        <Stack spacing={3} justifyContent='center' alignItems={'center'}>
            <Box component="img" src={QuestionIcon} sx={{ width: '88px' }} />
            <Typography variant="h4">
                <Trans
                    i18nKey={'views.onechip_cards.actions.meerkat.remove_ip.remove_operational.title'}
                />
            </Typography>
            <Stack spacing={1} justifyContent='center' alignItems={'center'}>
                <Typography sx={{ fontSize: '14px', textAlign: 'center', fontWeight: '500', color: '#7A7B84' }}>

                    <Trans
                        i18nKey={'views.onechip_cards.actions.meerkat.remove_ip.description'}
                    />
                </Typography>
            </Stack>
            {renderActions()}
        </Stack>
    );
};


const OperationModal = ({ isOpen, onClose, onSubmit, action, isLoading = false }) => {

    const operationComponentsConfig = useMemo(() => {
        let Component = null
        let componentProps = {};

        if (action.toUpperCase() === 'ASSIGN') {
            Component = AssignIp;
            componentProps = {
                isLoading,
                onClose,
                onSubmit: () => onSubmit(action)
            }
        }
        if (action.toUpperCase() === 'REMOVE') {
            Component = RemoveIp;
            componentProps = {
                isLoading,
                onClose,
                onSubmit: () => onSubmit(action)
            }

        }
        return {
            Component,
            componentProps


        }
    }, [action, isLoading]);

    const { Component, componentProps } = operationComponentsConfig;
    return (
        <ConfirmationModal
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={'xs'}
            mainIcon={QuestionIcon}
            fullWidth={false}
        >
            <Component {...componentProps} />
        </ConfirmationModal>
    );
};
export default OperationModal;
