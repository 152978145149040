import api from '../api';

const getJobProgress = async (requestId) => {
    const { data } = await api.get(`api/job-progress/request-id/${requestId}`);
    return data;
};

const JobProgressService = {
    getJobProgress
};

export default JobProgressService;
