import { SvgIcon } from "@mui/material";
import { AssignButtonContainer } from "modules/onechip-details/meerkat/containers/AssignContainer";
import { ReactComponent as PublicIcon } from 'assets/icons/assign_public_icon.svg';
import { ReactComponent as PrivateIcon } from 'assets/icons/assign_private_icon.svg';
import { useModal } from 'components/shared/modal';
import LoadingButton from "@mui/lab/LoadingButton";

const MeerkatPlanDetailsActions = ({ data, queryFilters, onComplete }) => {
    const modal = useModal();
    return <>
        <AssignButtonContainer modal={modal} data={data} type='public' onComplete={onComplete}>
            {({ onAssign, isLoadingAssign }) =>
                <LoadingButton
                    variant="contained"
                    fullWidth
                    startIcon={<SvgIcon component={PublicIcon} inheritViewBox />}
                    onClick={(event) => onAssign(event, queryFilters)}
                    loading={isLoadingAssign}
                    disabled={isLoadingAssign}
                >
                    Assign Public IP
                </LoadingButton>
            }
        </AssignButtonContainer>
        <AssignButtonContainer modal={modal} data={data} type='private' onComplete={onComplete}>
            {({ onAssign, isLoadingAssign }) =>
                < LoadingButton
                    variant="outlined"
                    fullWidth
                    startIcon={<SvgIcon component={PrivateIcon} sx={{ fill: 'none' }} inheritViewBox />}
                    onClick={(event) => onAssign(event, queryFilters)}
                    loading={isLoadingAssign}
                    disabled={isLoadingAssign}
                >
                    Assign Private IP
                </LoadingButton>
            }
        </AssignButtonContainer>
    </>
}

export default MeerkatPlanDetailsActions;