// project import
import 'reflect-metadata';
import Routes from './routes';
import ThemeCustomization from './themes';
import { SnackbarProvider } from 'notistack';
import { ErrorBoundary } from 'react-error-boundary';

import './i18n';
import ErrorBoundaryFallback from 'components/Error/ErrorBoundaryFallback';
import Snackbar from 'components/shared/snackbar/SnackBar';
import { makeStyles } from '@mui/styles';
import loglevel from 'loglevel';
import { useEffect, useLayoutEffect } from 'react';
import { ModalProvider } from 'components/shared/modal';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const useStyles = makeStyles((theme) => ({
    containerAnchorOriginTopRight: {
        right: '0 !important',
        top: '134px',
        '& .MuiCard-root': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        }
    }
}));

const App = () => {

    useLayoutEffect(() => {
        document.title = CONFIG.app.document.title;
    }, []);

    useEffect(() => {
        loglevel.setLevel(CONFIG.app.log.level);
    }, [])

    const classes = useStyles();
    return (
        <ThemeCustomization>
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <SnackbarProvider
                    preventDuplicate
                    dense={true}
                    autoHideDuration={10000}
                    classes={{
                        containerAnchorOriginTopRight: classes.containerAnchorOriginTopRight
                    }}
                    Components={{
                        error: Snackbar,
                        success: Snackbar,
                        warning: Snackbar,
                        info: Snackbar
                    }}
                    maxSnack={1}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                    <Routes />
                </SnackbarProvider>
            </ErrorBoundary>
        </ThemeCustomization>
    );
}

export default App;
