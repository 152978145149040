import { useEffect } from 'react';
import { useState } from 'react';
import { useAsyncNew } from './useAsyncNew';
import useInterval from './useInterval';

const usePoller = (service, delayInMilliseconds = 1000, immediate = false) => {
    const asyncService = useAsyncNew(service, { immediate, defaultState: { status: 'pending' } });
    const [enabled, setEnabled] = useState(immediate);

    const start = () => {
        setEnabled(true);
    }

    const stop = () => {
        setEnabled(false);
    }

    const restart = () => {
        stop();
        setTimeout(() => {
            start();
        }, 500);
    }

    useInterval(
        () => {
            asyncService.execute();
        },
        enabled ? delayInMilliseconds : null
    );

    return { data: asyncService, restart, stop, start };
};

export default usePoller;
