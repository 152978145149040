import { Stack } from "@mui/material";
import MainCard from "components/MainCard";
import { useEffect, useMemo, useState } from "react";
import ClientsService from "services/clients/Clients";

import Breadcrumbs from "components/breadcrumb";
import useStateAsync from "hooks/useStateAsync";
import { LoadingOutlined } from "@ant-design/icons";
import OnechipsSearchContainer from "components/top-section/containers/OnechipsSearchContainer";
import AccountSelectContainer from "components/top-section/containers/AccountSelectContainer";
import SelectPlan from 'modules/modals/SelectPlan/containers/SelectPlan';
import { useModal } from 'components/shared/modal/ModalContextProvider';
import { useSearchParams } from "react-router-dom";
import MeerkatSelectPlan from "modules/modals/SelectPlan/containers/MeerkatSelectPlan";
import BulkMeerkatSelectPlan from "modules/modals/SelectPlan/containers/BulkMeerkatSelectPlan";
import withAccountSelectionNotification from "hoc/withAccountSelectionNotification";

// ==============================|| MAIN LAYOUT ||============================== //

const componentsList = [
    {
        q: 'meerkat',
        component: SelectPlan
    }
]

const mainCardStyles = {
    height: '64px',
    position: 'relative',
    minWidth: '268px',
    backgroundColor: '#fff',
    mb: 2
};

const mainCardContentStyles = {
    display: 'flex',
    height: '100%',
    padding: '0 20px'
};


const TopSection = ({ children }) => {

    const modal = useModal();

    useEffect(() => {

        modal.registerNamedModal('PlanSelectionModal', SelectPlan, {},
            {
                fullWidth: true,
                maxWidth: "md",
                close: true,
            }
        );
        modal.registerNamedModal('MeerkatPlanSelectionModal', MeerkatSelectPlan, {},
            {
                fullWidth: true,
                maxWidth: "md",
                close: true
            }
        );

        modal.registerNamedModal('BulkMeerkatPlanSelectionModal', BulkMeerkatSelectPlan, {},
            {
                fullWidth: true,
                maxWidth: "md",
                close: true
            }
        );
    }, [])


    const clients = useStateAsync(ClientsService.getClientsList, { clientQueryParameterName: "clientIds", immediate: false });

    useEffect(() => {
        clients.execute({ 'sort.name': "ASC" });
    }, []);


    const showContent = useMemo(() => {
        if (clients.isSuccess || !clients.isInitialRequest) {
            return true;
        }
        return false;
    }, [clients])

    return (
        <>
            <MainCard contentSX={mainCardContentStyles} sx={mainCardStyles}>
                <Stack direction="row" alignItems="center" style={{ width: '100%' }} justifyContent="space-between">
                    <Breadcrumbs />
                    <Stack direction="row" justifySelf={'end'} spacing={6} alignItems="center" justifyContent="end">
                        <OnechipsSearchContainer />
                        <AccountSelectContainer />
                    </Stack>
                </Stack>
            </MainCard>
            {showContent ?
                <>
                    {children}
                </>
                : <LoadingOutlined style={{ fontSize: '36px', color: '#08c' }} />
            }
        </>
    );
};


export default TopSection;
