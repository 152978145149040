import useTimeout from 'hooks/useTimeout';
import { useCallback, useRef, useState } from 'react';


const useFakeLoader = (durationInSeconds) => {
    const [loading, setLoading] = useState(true);

    const callback = useCallback(() => {
        setLoading(loading => !loading);
    }, []);

    useTimeout(callback, loading ? durationInSeconds * 1000 : null);
    return loading;
}

export default useFakeLoader;