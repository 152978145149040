import axios from 'axios';
import AuthService from './auth/Auth';
import TokenService from './token/Token';
import qs from 'qs';

const api = axios.create({
    timeout: (60 * 1000),
    baseURL: CONFIG.app.api_base,
    paramsSerializer: params => {
        // skipNulls - skips null, undefined and empty string parameters
        // allowDots - allows query key parameters separated by dot character, example: a.b.c=a
        return qs.stringify(params, { allowDots: true, indices: false, skipNulls: true })
    }
});


api.interceptors.request.use(function (config) {
    // set login token authorization header for login and refresh token requests  
    if (config.url === '/auth/refresh') {
        const refreshToken = TokenService.getRefreshToken();
        if (refreshToken) {
            config.headers.authorization = `${refreshToken}`
        }
    } else {
        const accessToken = TokenService.getAccessToken();
        if (accessToken) {
            config.headers.authorization = `${accessToken}`
        }
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

api.interceptors.request.use(function (config) {
    const params = config.params;
    config.params = { ...params, portalType: CONFIG.app.portalType }
    return config;
}, (error) => {
    return Promise.reject(error);
});

const refreshAndRetryQueue = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

api.interceptors.response.use(response => response,
    async (error) => {
        const originalConfig = error.config;
        const { response } = error;

        if (response?.status === 401 && originalConfig.url !== '/auth/refresh') {
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    // Refresh the access token
                    const { token } = await AuthService.refreshAuth();

                    // Update the request headers with the new access token
                    error.config.headers['authorization'] = `Bearer ${token}`;

                    // Retry all requests in the queue with the new token
                    refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                        api
                            .request(config)
                            .then((response) => resolve(response))
                            .catch((err) => reject(err));
                    });

                    // Clear the queue
                    refreshAndRetryQueue.length = 0;

                    // Retry the original request
                    return api(originalConfig);
                } catch (refreshError) {
                    AuthService.logout();
                } finally {
                    isRefreshing = false;
                }
            }
            return new Promise((resolve, reject) => {
                refreshAndRetryQueue.push({ config: originalConfig, resolve, reject });
            });
        }
        return Promise.reject(error);
    }
);

export default api;