import * as React from "react";
import ConfirmationModal from "components/shared/modal/ConfirmationModal";
import JobProgressLoader from "components/job-progress-loader/JobProgressLoader";


const InstallPlan = ({ onClose = () => { }, requestId, onFinish }) => {

    const [progressModalOpen, setProgressModalOpen] = React.useState(true);

    const handleJobFinish = (status, { data, error }) => {
        onFinish(status, { data, error });;
        setProgressModalOpen(false);
    };

    return (
        <>
            <ConfirmationModal isOpen={progressModalOpen} onClose={() => onClose} title={""}>
                <JobProgressLoader size={64} requestId={requestId} onFinish={handleJobFinish} />
            </ConfirmationModal>
        </>
    );
};
export default InstallPlan;
