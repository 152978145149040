import { useAsyncNew } from '../../hooks/useAsyncNew';
import NetworkAppsService from '../../services/network-apps/NetworkApps';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { Box, Chip, Stack } from '@mui/material';
import ServerAutoComplete from '../../components/shared/server-auto-complete';
import SimpleSelect from '../../components/select/SimpleSelect';
import CloseIcon from '@mui/icons-material/Close';
import Search from 'components/shared/search';

const PlanFilters = ({ onFilter, isModal = false, readOnly = {}, selected = {} }) => {
  const predefinedData = {
    coverageTypes: [
      { value: 'Single', label: 'Single' },
      { value: 'Multi', label: 'Multi' },
    ],
    credentialTypes: [
      {
        value: 'global',
        label: 'Global',
      },
      {
        value: 'local',
        label: 'Local',
      },
      {
        value: 'native',
        label: 'Native',
      },
    ],
    networkTypes: [
      {
        value: 'Public',
        label: 'Public Network',
      },
      {
        value: 'Private',
        label: 'Private Network',
      }],
    radioTechs: [
      { value: '5G', label: '5G' },
      { value: '3G', label: '3G' },
      { value: '2G', label: '2G' },
      { value: 'LTE', label: 'LTE' },
      { value: 'LTE_M', label: 'LTE_M' },
      { value: 'LTE_3G', label: 'LTE/3G' },
      { value: 'P_LTE', label: 'Private LTE' },
      { value: 'NB-IoT', label: 'NB-IoT' },
      { value: 'Other', label: 'Other' },
    ],
    services: [
      {
        value: 'meerkat',
        label: 'Meerkat',
      },
    ],

  };

  const [search, setSearch] = useState(null);
  const [operators, setOperators] = useState({});
  const [countriesSearchValue, setCountriesSearchValue] = useState('');

  const countries = useAsyncNew(NetworkAppsService.getCountriesList, { immediate: false });

  const handleOnCountrySearch = async (value) => {
    setCountriesSearchValue(value);
    await countries.execute({ title: value, limit: 10, 'sort.title': 'ASC' });
  };

  const getDefaultFilters = () => {
    const defaultFilters = {
      coverageCountries: [],
      networkType: predefinedData.networkTypes,
      coverageType: predefinedData.coverageTypes,
      networkTechType: predefinedData.radioTechs,
      service: predefinedData.services,
      credentialsType: predefinedData.credentialTypes,
      networkProviders: [],
    };
    return Object.keys(defaultFilters).reduce((acc, name) => {

      acc[name] = defaultFilters[name].filter(field => (selected[name] ?? []).includes(field.value));

      return acc;
    }, {});
  }

  const [filters, setFilters] = useState(getDefaultFilters());

  const selectedList = useMemo(() => {
    return Object.keys(filters).reduce((acc, name) => {
      if (selected[name]) {
        acc[name] = selected[name];
      } else {
        acc[name] = filters[name].map(field => field.value);
      }
      return acc;
    }, {});
  }, [filters, selected])

  useDidMountEffect(() => {
    onFilter({ name: search, ...selectedList });
  }, [search, filters]);


  const clearFilter = (name, value) => {
    if (name === 'coverageCountries') {
      setOperators(prev => {
        const { [value]: omit, ...rest } = prev;
        return { ...rest };
      });
    }
    setFilters(prev => ({ ...prev, [name]: prev[name].filter(filter => filter.value !== value) }));
  };

  const handleMenuItemSelect = (filterName, values) => {
    setFilters(prev => ({ ...prev, [filterName]: values }));
  };

  const handleCountryMenuItemSelect = (values) => {
    const operators = values.reduce((acc, item) => {
      acc[item.value] = item.raw.networkProviders;
      return acc;
    }, {});

    setOperators(operators);
    handleMenuItemSelect('coverageCountries', values);
  };

  const handleOnSearch = (value) => {
    setSearch(value);
  };

  const clearSearchFilter = () => {
    setSearch('');
  };

  const getOperators = () => {
    return Object.entries(operators).reduce((acc, item) => {
      const [key, value] = item;
      return [...acc, ...value];
    }, []);
  };

  return <Stack spacing={2} alignItems={'start'} sx={{ width: '100%', mb: 4 }}>

    <Stack spacing={1} direction={isModal ? 'column' : 'row'} sx={{ width: '100%', minHeight: '40px' }}>
      <Box>
        <Search value={search} onSearch={handleOnSearch} sx={{ width: '100%' }} />
      </Box>
      <Stack direction={'row'} spacing={1}>
        <Box sx={{ my: '0 !important', flex: 1, minWidth: '133px' }}>
          <ServerAutoComplete
            label={'Countries'}
            InputProps={{
              label: 'Countries',
              placeholder: null,
            }}
            size="medium"
            multiple={true}
            getOptionValue={(option) => option.isoCode}
            onSelect={(items) => handleCountryMenuItemSelect(items)}
            onOpen={() => countries.execute()}
            loading={countries.isLoading}
            onSearch={handleOnCountrySearch}
            data={countries?.data?.countries}
            transform={(item) => item}
            getOptionLabel={(option) => option?.name || ''}
            value={countriesSearchValue}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.isoCode}>
                  {option.name}
                </li>
              );
            }} />
        </Box>

        {
          <Box sx={{ my: '0 !important', flex: 1, minWidth: '133px' }}>
            <SimpleSelect
              inputProps={{ readOnly: readOnly?.networkProviders ?? false }}
              disabled={Object.keys(operators).length === 0}
              multiple={true}
              readOnly={readOnly?.networkProviders ?? false}
              onMenuItemSelect={(items) => handleMenuItemSelect('networkProviders', items)}
              label={'Operators'}
              menuItems={getOperators()}
              selectedFilters={selectedList['networkProviders']} />
          </Box>
        }
        <Box sx={{ my: '0 !important', flex: 1, minWidth: '133px' }}>
          <SimpleSelect
            inputProps={{ readOnly: readOnly?.networkTechType ?? false }}
            multiple={true}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            onMenuItemSelect={(items) => handleMenuItemSelect('networkTechType', items)}
            label={'Radio Tech'}
            menuItems={predefinedData.radioTechs}
            selectedFilters={selectedList['networkTechType']} />
        </Box>
        <Box sx={{ my: '0 !important', flex: 1, minWidth: '133px' }}>
          <SimpleSelect
            inputProps={{ readOnly: readOnly?.coverageType ?? false }}
            multiple={true}
            onMenuItemSelect={(items) => handleMenuItemSelect('coverageType', items)}
            label={'Coverage'}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            menuItems={predefinedData.coverageTypes}
            selectedFilters={selectedList['coverageType']} />
        </Box>
      </Stack>
      <Stack direction={'row'} spacing={1}>
        <Box sx={{ my: '0 !important', flex: 1, minWidth: '133px' }}>
          <SimpleSelect
            multiple={true}
            onMenuItemSelect={(items) => handleMenuItemSelect('service', items)}
            inputProps={{ readOnly: readOnly?.service ?? false }}
            label={'Services'}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            menuItems={predefinedData.services}
            selectedFilters={selectedList['service']} />
        </Box>
        <Box sx={{ my: '0 !important', flex: 1, minWidth: '133px' }}>
          <SimpleSelect
            inputProps={{ readOnly: readOnly?.credentialsType ?? false }}
            multiple={true}
            onMenuItemSelect={(items) => handleMenuItemSelect('credentialsType', items)}
            label={'Credential'}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            menuItems={predefinedData.credentialTypes}
            selectedFilters={selectedList['credentialsType']} />
        </Box>
        <Box sx={{ my: '0 !important', flex: 1, minWidth: '133px' }}>
          <SimpleSelect
            inputProps={{ readOnly: readOnly?.networkType ?? false }}
            multiple={true}
            onMenuItemSelect={(items) => handleMenuItemSelect('networkType', items)}
            label={'Networks'}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            menuItems={predefinedData.networkTypes}
            selectedFilters={selectedList['networkType']} />
        </Box>
        {isModal && <Box sx={{ my: '0 !important', flex: 1, minWidth: '133px' }}></Box>}
      </Stack>
    </Stack>
    <Stack spacing={1} direction="row">
      {Object.entries(filters).map(([filterName, filterValues]) => (
        <Fragment key={filterName}>
          {filterValues.map((filter) => (
            <Chip
              color="primary"
              disabled={readOnly[filterName] ?? false}
              key={filter.value}
              label={filter.label}
              onDelete={() => clearFilter(filterName, filter.value)}
              deleteIcon={<CloseIcon />}
            />
          ))}
        </Fragment>
      ))}
      {search && search.length > 0 &&
        <Chip
          color="primary"
          label={search}
          onDelete={() => clearSearchFilter()}
          deleteIcon={<CloseIcon />}
        />
      }
    </Stack>
  </Stack>;
};

export default PlanFilters;
