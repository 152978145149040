import React from "react";
import { Box } from "@mui/material";
import PlanLogo from "./PlanLogo";

const ImageContainer = ({ images, names }) => {
  const containerStyle = {
    display: "flex",
    justifyContent: "space-between", // To evenly distribute elements with space in between
    alignItems: "center", // Align elements vertically in the center
    flexWrap: "wrap", // Allow elements to wrap to the next row if they don't fit
    gap: "10px", // Add a 10px gap between elements
    marginTop: "20px",
    marginBottom: "20px"
  };

  return (
    <Box sx={containerStyle}>
      {images && images.length > 0 ? (
        images.map((imageUrl, index) => (
          <div key={index} style={{ flex: "0 0 auto" }}> {/* Ensure elements are not stretched */}
            <PlanLogo key={index} planLogoUrls={[imageUrl]} />
          </div>
        ))
      ) : (
        // Render an alternative component or message when planLogoUrls is empty or undefined
        <div
          style={{
            width: "90px",
            height: "90px",
            borderRadius: "4px",
            border: "1px"
          }}
        />
      )}
    </Box>
  );
};


export default ImageContainer;
