import Error from 'components/Error/RequestFailure';

const withError = (Component, config = { sx: {} }) => {
    return (props) => {
        const { status, isLoading = false, execute = () => { } } = props;
        const isError = status === 'error';
        const newProps = { ...props, loading: status === 'pending' || status === 'idle' || isLoading };
        return <>{isError ? <Error retryCallback={execute} {...config} /> : <Component {...newProps} />}</>;
    };
};

export default withError;
