import api from '../api';

const getDataConsumptionByCountries = async (params) => {
    const { data } = await api.get(`api/data-consumption/countries`, {
        params
    });
    return data.data;
};

const getTopDataConsumption = async (params) => {
    const { data } = await api.get(`api/data-consumption/top`, {
        params
    });
    return data.data;
};

const getTopPlanDataConsumption = async (params) => {
    const { data } = await api.get(`api/data-consumption/plan/top`, {
        params
    });
    return data.data;
};

const getTotalDataConsumption = async (params) => {
    const { data } = await api.get(`api/data-consumption/total`, {
        params
    });
    return data.data;
};

const getDailyDataConsumption = async (params) => {
    const { data } = await api.get(`api/data-consumption/daily`, {
        params
    });
    return data.data;
};

const getRecentMonthsDataConsumption = async (params) => {
    const { data } = await api.get(`api/data-consumption/period`, {
        params
    });
    return data;
};

const getDailyDataConsumptionData = async (params) => {
    return await Promise.all([getDailyDataConsumption(params), getRecentMonthsDataConsumption(params)]);
};



const DataConsumptionService = {
    getDataConsumptionByCountries,
    getTotalDataConsumption,
    getDailyDataConsumption,
    getTopDataConsumption,
    getTopPlanDataConsumption,
    getRecentMonthsDataConsumption,
    getDailyDataConsumptionData
}

export default DataConsumptionService;
