import React, { useState, useEffect, useRef } from 'react';


const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // combining the setInterval and 
    //clearInterval methods based on delay.
    useEffect(() => {

        if (delay === null) {
            return;
        }
        let id = setInterval(async () => savedCallback.current(), delay);
        return () => clearInterval(id);

    }, [delay]);
}

export default useInterval;