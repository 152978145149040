import { useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Grid } from "@mui/material";

import InfiniteScroll from "react-infinite-scroll-component";

const InfinityScrollWrapper = ({ data, onFetchMore, isLoading, isSuccess, isNew, children }) => {

    const total = useMemo(() => {
        return data?.total;
    }, [isSuccess]) || 0;


    const [state, setState] = useState(
        {
            items: [],
            hasMore: true
        });

    useEffect(() => {
        if (isSuccess) {
            const newData = data?.data || [];
            setState(prev => {
                const result = isNew ? newData : [...prev.items, ...newData];
                return { ...prev, items: result, hasMore: total - result.length > 0 }
            })
        }
    }, [isSuccess])



    const fetchMoreData = () => {
        if (state.items.length >= total) {
            return;
        }
        onFetchMore(state.items.length);
    };


    return <><Box id="scrollableDiv">
        <InfiniteScroll
            style={{ padding: '8px' }}
            dataLength={state.items.length}
            next={fetchMoreData}
            hasMore={state.hasMore}
            loader={<CircularProgress size={20} />}
            height={'400px'}
            initialScrollY={0}
            scrollThreshold={0.9}
            scrollableTarget="scrollableDiv"
            endMessage={null}
        >
            {isNew && isLoading ?
                null :
                children(state)
            }
        </InfiniteScroll>
    </Box>
    </>
}

export default InfinityScrollWrapper;