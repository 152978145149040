import api from '../api';


const getPlansList = async (params) => {
    const { data } = await api.get(`api/plans`, { params });
    return data;
};

const getPlanChangeStatusDetailsByEid = async (eid) => {
    const { data } = await api.get(`api/get-status-details/${eid}`);
    return data;
};

const downloadPlanReport = async (params) => {
    const { data } = await api.get(`api/plans/marketplace/download-plan-report`, { params });
    return data;
};

const getPlanPriceDetailByClientId = async (planUuid, clientId, params) => {
    const { data } = await api.get(`api/plan/${planUuid}/client/${clientId}`, { params });
    return data;
};

const PlanService = {
    getPlanChangeStatusDetailsByEid,
    getPlansList,
    downloadPlanReport,
    getPlanPriceDetailByClientId
};

export default PlanService;
